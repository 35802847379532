import * as Types from '../types';

import { api } from '../../connectors/sp';
export type TransferMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TransferMutation = { __typename?: 'Mutation', transfer: boolean };

export type TeeTransferMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TeeTransferMutation = { __typename?: 'Mutation', teeTransfer: boolean };


export const TransferDocument = `
    mutation Transfer {
  transfer
}
    `;
export const TeeTransferDocument = `
    mutation TeeTransfer {
  teeTransfer
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    Transfer: build.mutation<TransferMutation, TransferMutationVariables | void>({
      query: (variables) => ({ document: TransferDocument, variables })
    }),
    TeeTransfer: build.mutation<TeeTransferMutation, TeeTransferMutationVariables | void>({
      query: (variables) => ({ document: TeeTransferDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useTransferMutation, useTeeTransferMutation } = injectedRtkApi;

