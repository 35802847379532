import {
  useCallback, useMemo, memo, FC,
} from 'react';
import cn from 'classnames';
import { Dropdown } from 'uikit/Dropdown';
import { Item } from 'uikit/Dropdown/types';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { reset, selectedAddressSelector } from 'lib/features/wallet';
import { useAuth } from 'common/contexts/Auth/AuthContext';
import { getItems } from './helpers';
import { AccountDropdownToggleContent } from './AccountDropdownToggle';
import classes from './AccountDropdown.module.scss';
import { AccountDropdownProps, Events } from './types';

export const AccountDropdown: FC<AccountDropdownProps> = memo(({ theme }) => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const items = useMemo(() => getItems(selectedAddress), [selectedAddress]);
  const renderToggleContent = useCallback((item?: Item) => {
    return <AccountDropdownToggleContent text={item?.label} />;
  }, []);
  const onSelect = useCallback((value: any) => {
    if (value === Events.DISCONNECT) {
      logout();
      dispatch(reset());
    }
  }, [logout, dispatch]);

  return (
    <Dropdown
      items={items}
      active={selectedAddress}
      renderToggleContent={renderToggleContent}
      isShowActive={false}
      classNameDropdownToggle={cn(classes.dropdownToggle, classes[theme])}
      classNameDropdownMenu={cn(classes.dropdownMenu, classes[theme])}
      onSelect={onSelect}
      theme={theme}
      dataTestId="dropdown-account"
    />
  );
});