import { Item } from 'uikit/Dropdown/types';
import getConfig from 'config';
import { Events } from './types';

const {
  NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS, NEXT_PUBLIC_NETWORK_SCAN,
} = getConfig();

export const getItems = (): Item[] => {
  return [
    {
      label: 'View in explorer',
      href: `${NEXT_PUBLIC_NETWORK_SCAN}/address/${NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS || ''}`,
      external: true,
    },
    { label: 'All orders', href: '/all-orders' },
    { label: 'Check Confidentiality', value: Events.Check },
  ];
};