import { useState, useCallback } from 'react';

import { sleep } from 'common/utils';

export const useDelayedUnmount = (isInitiallyMounted: boolean | undefined, delay: number, func?: Function): any => {
  const [isMounted, setIsMounted] = useState(isInitiallyMounted);
  const [isUnmounting, setIsUnmounting] = useState(false);

  const setIsMountedFync = useCallback(async (_isMounted: boolean) => {
    if (isMounted === _isMounted || isUnmounting) { return; }
    if (!_isMounted) {
      setIsUnmounting(true);
      await sleep(delay);
      func?.();
      setIsUnmounting(false);
    }
    setIsMounted(_isMounted);
  }, [isMounted, delay, setIsMounted, setIsUnmounting, isUnmounting, func]);

  return [[isMounted, isUnmounting], setIsMountedFync];
};
