import { FC, memo, useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getStringWithEllipsis } from 'common/utils';
import { TooltipWithEllipsisProps } from '../types';
import classes from './TooltipWithEllipsis.module.scss';

export const TooltipWithEllipsis: FC<TooltipWithEllipsisProps> = memo(({ placement = 'top', children, maxLen }) => {
  const overlay = useCallback(({ show, ...props }) => (
    <Tooltip {...props} id="panel-tooltip" bsPrefix="panel-tooltip" className={classes.overlay}>
      <div>{children}</div>
    </Tooltip>
  ), [children]);
  return (
    <OverlayTrigger
      placement={placement}
      overlay={overlay}
    >
      <span>
        {typeof maxLen === 'number' && typeof children === 'string' ? getStringWithEllipsis(children, maxLen) : children}
      </span>
    </OverlayTrigger>
  );
});
