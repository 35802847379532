import { memo, FC } from 'react';
import { useAppSelector } from 'lib/hooks';
import { Button } from 'uikit/Buttons/Button';
import { Spinner } from 'uikit/Spinner';
import { themeSelector } from 'lib/features/theme';
import { Theme } from 'uikit/common/types';
import classes from './Btn.module.scss';
import { BtnProps } from './types';

export const Btn: FC<BtnProps> = memo(({
  onClick, text, img, loading, className,
}) => {
  const theme = useAppSelector(themeSelector);
  return (
    <Button
      theme={theme}
      variant={theme === Theme.dark ? 'black' : 'base'}
      onClick={onClick}
      className={className}
    >
      {loading ? <Spinner /> : img}
      {!!text && <span className={classes.text}>{text}</span>}
    </Button>
  );
});