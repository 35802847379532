import { sliceWithDot } from 'common/utils';
import { Item } from 'uikit/Dropdown/types';
import { Events } from './types';

export const getItems = (selectedAddress?: string): Item[] => {
  if (!selectedAddress) return [];
  return [
    { label: sliceWithDot(selectedAddress), value: selectedAddress },
    { label: 'Disconnect', value: Events.DISCONNECT },
  ];
};