import * as Types from '../types';

import { api } from '../../connectors/sp';
export type EventSubscriptionVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.EventFilter>;
}>;


export type EventSubscription = { __typename?: 'Subscription', event: { __typename?: 'SubscriptionPayload', data?: Array<string> | null, type: Types.SubscriptionType, subscriptionSource: Types.SubscriptionSource } };

export type OrderStatusUpdatedSubscriptionVariables = Types.Exact<{
  input: Types.OrderStatusUpdatedInput;
}>;


export type OrderStatusUpdatedSubscription = { __typename?: 'Subscription', orderStatusUpdated: { __typename?: 'OrderStatusUpdatedPayload', orderId: string, status: string } };

export type WorkflowCreatedSubscriptionVariables = Types.Exact<{
  input: Types.WorkflowCreatedInput;
}>;


export type WorkflowCreatedSubscription = { __typename?: 'Subscription', workflowCreated: { __typename?: 'WorkflowCreatedPayload', consumer: string, externalId: string, offerId: string, orderId: string } };

export type OrderEventSubscriptionVariables = Types.Exact<{
  consumer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  eventTypes?: Types.InputMaybe<Array<Types.OrderEventType> | Types.OrderEventType>;
  externalId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderIds?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type OrderEventSubscription = { __typename?: 'Subscription', order: { __typename?: 'OrderPayload', eventType?: Types.OrderEventType | null, order: { __typename?: 'OrderObject', id: string, consumer: string, orderDeposit?: string | null, depositSpent?: string | null, totalDeposit?: string | null, totalDepositUnspent?: string | null, offerType: string, parentOrder?: string | null, subOrders: Array<string>, origins?: { __typename?: 'Origins', createdBy: string, createdDate: number, modifiedBy: string, modifiedDate: number } | null, offerInfo?: { __typename?: 'OfferInfo', name: string, offerType: string, cancelable: boolean, description: string, restrictions?: { __typename?: 'OfferRestrictions', types?: Array<string> | null } | null } | null, accumulatedSlotInfo: { __typename?: 'SlotInfo', cpuCores: number, diskUsage: number, ram: number }, accumulatedSlotUsage: { __typename?: 'SlotUsage', priceType: Types.PriceType, price: string, minTimeMinutes: number, maxTimeMinutes: number }, orderInfo: { __typename?: 'OrderInfo', status: string, offerId: string, encryptedArgs: string }, teeOfferInfo?: { __typename?: 'TeeOfferInfo', name: string, description: string, properties: string } | null, orderResult: { __typename?: 'OrderResult', encryptedResult?: string | null }, selectedUsage: { __typename?: 'OrderUsage', slotInfo: { __typename?: 'SlotInfo', cpuCores: number, diskUsage: number, ram: number }, optionInfo: Array<{ __typename?: 'OptionInfo', bandwidth: number, externalPort: number, traffic: number }>, slotUsage: { __typename?: 'SlotUsage', price: string, priceType: Types.PriceType } } } } };

export type OrderEventsUpdatedSubscriptionVariables = Types.Exact<{
  input: Types.OrderEventsUpdatedInput;
}>;


export type OrderEventsUpdatedSubscription = { __typename?: 'Subscription', orderEventsUpdated: { __typename?: 'OrderEventsUpdated', _id: string, name: string, orderId: string, orderStatus?: string | null, createdAt: any, transactionHash: string, transactionTimestamp: number, value?: string | null, change?: string | null, deposit?: string | null, profit?: string | null } };


export const EventDocument = `
    subscription Event($filter: EventFilter) {
  event(filter: $filter) {
    data
    type
    subscriptionSource
  }
}
    `;
export const OrderStatusUpdatedDocument = `
    subscription OrderStatusUpdated($input: OrderStatusUpdatedInput!) {
  orderStatusUpdated(input: $input) {
    orderId
    status
  }
}
    `;
export const WorkflowCreatedDocument = `
    subscription WorkflowCreated($input: WorkflowCreatedInput!) {
  workflowCreated(input: $input) {
    consumer
    externalId
    offerId
    orderId
  }
}
    `;
export const OrderEventDocument = `
    subscription OrderEvent($consumer: String, $eventTypes: [OrderEventType!], $externalId: String, $orderIds: [String!]) {
  order(
    consumer: $consumer
    eventTypes: $eventTypes
    externalId: $externalId
    orderIds: $orderIds
  ) {
    eventType
    order {
      id
      consumer
      origins {
        createdBy
        createdDate
        modifiedBy
        modifiedDate
      }
      offerInfo {
        name
        offerType
        cancelable
        description
        restrictions {
          types
        }
      }
      orderDeposit
      depositSpent
      totalDeposit
      totalDepositUnspent
      offerType
      accumulatedSlotInfo {
        cpuCores
        diskUsage
        ram
      }
      accumulatedSlotUsage {
        priceType
        price
        minTimeMinutes
        maxTimeMinutes
      }
      orderInfo {
        status
        offerId
        encryptedArgs
      }
      teeOfferInfo {
        name
        description
        properties
      }
      parentOrder
      orderResult {
        encryptedResult
      }
      selectedUsage {
        slotInfo {
          cpuCores
          diskUsage
          ram
        }
        optionInfo {
          bandwidth
          externalPort
          traffic
        }
        slotUsage {
          price
          priceType
        }
      }
      subOrders
    }
  }
}
    `;
export const OrderEventsUpdatedDocument = `
    subscription OrderEventsUpdated($input: OrderEventsUpdatedInput!) {
  orderEventsUpdated(input: $input) {
    _id
    name
    orderId
    orderStatus
    createdAt
    transactionHash
    transactionTimestamp
    value
    change
    deposit
    profit
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
  }),
});

export { injectedRtkApi as api };
export const {  } = injectedRtkApi;

