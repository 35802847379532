import { memo, FC } from 'react';
import Image from 'next/image';
import { Box } from 'uikit/Box';
import { AccountDropdownToggleContentProps } from './types';
import classes from './AccountDropdownToggleContent.module.scss';

export const AccountDropdownToggleContent: FC<AccountDropdownToggleContentProps> = memo(({ text }) => {
  return (
    <Box alignItems="center">
      <Box className={classes.mr}>
        <Image src="/icons/metamask.png" alt="metamask" width={23} height={23} />
      </Box>
      <Box>
        {text}
      </Box>
    </Box>
  );
});