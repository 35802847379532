import { useMemo } from 'react';
import Image from 'next/image';

import { Box } from 'uikit/Box';
import { AccountBalance } from 'components/Account/AccountBalance';
import { GetMatic } from 'components/Header/GetMatic/GetMatic';
import { GetTee } from 'components/Header/GetTee/GetTee';
import { TokensProps } from './types';
import classes from './Tokens.module.scss';

export const Tokens = ({ theme }: TokensProps) => {
  const src = useMemo(() => `/icons/polygon_${theme}.png`, [theme]);

  return (
    <Box direction="column" className={classes.container}>
      <Box justifyContent="space-between">
        <AccountBalance />
        <Image src={src} alt="polygon" width={28} height={28} />
      </Box>
      <Box justifyContent="space-between" className={classes.btnGroup}>
        <GetMatic className={classes.btn} />
        <GetTee className={classes.btn} />
      </Box>
    </Box>
  );
};
