import Head from 'next/head';

export const PageHead = () => (
  <Head>
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, interactive-widget=resizes-content"
    />
    <meta name="description" content="Marketplace" />
    <title>Marketplace</title>
    <link rel="shortcut icon" href="/favicon.png" />
  </Head>
);
