import {
  useCallback, useMemo, useState, memo,
} from 'react';
import cn from 'classnames';

import { Dropdown } from 'uikit/Dropdown';
import { Modal } from 'uikit/Modal';
import { Icon } from 'uikit/Icon';
import { SquareButton } from 'uikit/Buttons/SquareButton';
import { Theme } from 'uikit/common/types';
import { CheckConfidentialityModal } from './CheckConfidentialityModal';
import { CheckConfidentialityProps, Events } from './types';
import { getItems } from './helpers';
import classes from './CheckConfidentiality.module.scss';

export const CheckConfidentiality = memo(({ theme }: CheckConfidentialityProps) => {
  const [showCheckConfidentialityModal, setShowCheckConfidentialityModal] = useState(false);
  const items = useMemo(() => getItems(), []);

  const renderToggleContent = useCallback(() => {
    return (
      <SquareButton
        variant={theme === Theme.dark ? 'transparent' : 'white'}
        theme={theme}
        size="large"
        className={classes.btn}
        data-testid="button-check-confidentiality"
      >
        <Icon name="app" width={10} />
      </SquareButton>
    );
  }, [theme]);
  const onCloseResultModal = useCallback(async () => {
    setShowCheckConfidentialityModal(false);
  }, []);
  const onSelect = useCallback((value: any) => {
    if (value === Events.Check) {
      setShowCheckConfidentialityModal(true);
    }
  }, []);

  return (
    <>
      <Modal {...{
        show: showCheckConfidentialityModal,
        onClose: onCloseResultModal,
        theme,
        dialogClassName: classes.dialog,
        bodyClassName: classes.bodyModal,
      }}
      >
        <CheckConfidentialityModal theme={theme} />
      </Modal>
      <Dropdown
        items={items}
        renderToggleContent={renderToggleContent}
        className={classes.content}
        classNameDropdownToggle={cn(classes.dropdownToggle, classes[`dropdownToggle${theme}`])}
        classNameDropdownMenu={classes.dropdownMenu}
        onSelect={onSelect}
        theme={theme}
        dataTestId="dropdown-check-confidentiality"
        showArrow={false}
        id="check-confidentiality"
      />
    </>
  );
});
