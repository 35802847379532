import { useCallback } from 'react';
import { changeWallet } from 'lib/features/wallet/thunks';
import { getAction } from 'lib/features/wallet';
import { ChangeWalletArgs } from 'lib/features/wallet/types';
import { WalletType } from 'common/types/wallet';
import { useAppDispatch } from 'lib/hooks';
// import useSecretKeeper from './useSecretKeeper';

export interface ConnectWalletProps extends Omit<ChangeWalletArgs, 'actions'> {}

const useWalletConnector = () => {
  // const { debounceConnect: debounceConnectToSecretKeeper, disconnect } = useSecretKeeper();
  const dispatch = useAppDispatch();

  // const onUpdate = useCallback((address?: string) => {
  //   debounceConnectToSecretKeeper(address);
  // }, [debounceConnectToSecretKeeper]);

  // const onResetState = useCallback(() => {
  //   disconnect();
  // }, [disconnect]);

  const getWalletAction = useCallback((walletType: WalletType | null) => getAction({
    walletType,
    dispatch,
    // onUpdate,
    // onResetState,
  }), [dispatch]);

  const connectWallet = useCallback((props: ConnectWalletProps) => {
    return dispatch(changeWallet({ ...props, actions: getWalletAction(props?.walletType) }));
  }, [getWalletAction, dispatch]);

  return {
    connectWallet,
  };
};

export default useWalletConnector;