import { useCallback } from 'react';
import cn from 'classnames';
import { useAppSelector } from 'lib/hooks';
import { isConnectedSelector } from 'lib/features/wallet';
import { WalletType } from 'common/types/wallet';
import useWalletConnector from 'common/hooks/useWalletConnector';
import { Box } from 'uikit/Box';
import { Cross } from 'uikit/Cross';
import { Button } from 'uikit/Buttons';
import { AccountInfo } from './AccountInfo';
import { PanelHeader } from './PanelHeader';
import { Tokens } from './Tokens';
import { PanelProps } from './types';
import classes from './Panel.module.scss';

export const Panel = ({
  theme, isUnmounting, setIsMounted,
}: PanelProps) => {
  const { connectWallet } = useWalletConnector();
  const isConnected = useAppSelector(isConnectedSelector);
  const onClick = useCallback((e) => {
    e.preventDefault();
    setIsMounted(false);
  }, [setIsMounted]);

  const onConnect = useCallback((e) => {
    e.preventDefault();
    connectWallet({
      walletType: WalletType.MetaMask, isSendMetrics: true,
    });
  }, [connectWallet]);

  return (
    <Box className={cn(classes.container, classes[theme], { [classes.hide]: isUnmounting })}>
      <PanelHeader theme={theme} onClickCross={onClick} />
      <Box direction="column" className={classes.left}>
        {isConnected
          ? (
            <>
              <AccountInfo />
              <Tokens theme={theme} />
            </>
          ) : (

            <Button className={classes.btn} onClick={onConnect}>Connect wallet</Button>
          )}
      </Box>
      <Cross theme={theme} onClick={onClick} className={classes.cross} />
    </Box>
  );
};
