import React, {
  memo,
  FC,
  useCallback,
} from 'react';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { selectedAddressSelector } from 'lib/features/wallet';
import { refillTEE } from 'lib/features/sdk/thunks';
import { useTeeTransferMutation } from 'generated/schemas/mutations';
import { themeSelector } from 'lib/features/theme';
import { Theme } from 'uikit/common/types';
import { refillTeeLoadingSelector } from 'lib/features/sdk';
import { Button } from 'uikit/Buttons/Button';
import { useAuth } from 'common/contexts/Auth/AuthContext';
import { GetTeeProps } from './types';

export const GetTee: FC<GetTeeProps> = memo(({ className }) => {
  const theme = useAppSelector(themeSelector);
  const dispatch = useAppDispatch();
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const loading = useAppSelector(refillTeeLoadingSelector);
  const [transfer] = useTeeTransferMutation() as any;
  const onRefillTee = useCallback(async () => {
    return dispatch(refillTEE({ transfer }));
  }, [transfer, dispatch]);

  const { runEvent } = useAuth(onRefillTee);

  if (!selectedAddress) return null;

  return (
    <Button
      variant={theme === Theme.dark ? 'black' : 'white'}
      theme={theme}
      loading={loading}
      className={className}
      onClick={runEvent}
      data-testid="button-gettee"
    >
      Get TEE
    </Button>
  );
});
