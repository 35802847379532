import { memo, FC, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Notification } from 'uikit/Notification';
import { Box } from 'uikit/Box';
import { Divider } from 'uikit/Divider';
import { WalletType } from 'common/types/wallet';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Icon } from 'uikit/Icon';
import { Btn } from './Btn';
import { SelectWalletTypes } from './types';
import classes from './SelectWallet.module.scss';
import { hrefGetAccessToken, hrefFollowGuide } from './helpers';

export const SelectWallet: FC<SelectWalletTypes> = memo(({ onConnect, loading, error }) => {
  const theme = useAppSelector(themeSelector);
  const onClick = useCallback((walletType: WalletType) => () => onConnect(walletType), [onConnect]);
  return (
    <Box direction="column" className={classes[theme]} alignItems="center">
      <Box className={classes.title}>Login to Marketplace</Box>
      <Box className={classes.subTitle}>Please use your Super testnet account to login.</Box>
      {!!error?.message && (
        <Notification className={classes.error} variant="error">{error.message}</Notification>
      )}
      <Box className={classes.btns} justifyContent="center">
        <Btn
          onClick={onClick(WalletType.MetaMask)}
          img={(
            <Image
              src="/icons/metamask.png"
              alt="metamask"
              width={23}
              height={23}
            />
          )}
          text="MetaMask"
          className={classes.btn}
          loading={loading}
        />
      </Box>
      {!loading && (
        <>
          <Notification
            theme={theme}
            variant="warning"
            className={classes.info}
            classNameIcon={classes.notificationIcon}
          >
            <span>
              Accounts must be imported into Metamask and use the Polygon Amoy network.&nbsp;Follow&nbsp;
              <Link
                href={hrefFollowGuide}
                target="_blank"
                className={classes.linkNotification}
              >
                this guide.
              </Link>
            </span>
          </Notification>
          <Divider theme={theme} className={classes.divider} />
          <Box className={classes.linkBlock} justifyContent="center" alignItems="center">
            Don’t have a testnet account?
            <Link
              href={hrefGetAccessToken}
              target="_blank"
              className={classes.link}
            >
              <Box className={classes.textLink}>
                Get it here
              </Box>
              <Icon name="link_arrow" width={9} className={classes.icon} />
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
});