import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { useDelayedUnmount } from 'common/hooks/useDelayedUnmount';
import { DocInfoIcon } from 'components/Account/DocInfoIcon';
import { useAppSelector } from 'lib/hooks';
import { isMobileSelector } from 'lib/features/windowSize';
import { Logo } from '../Logo';
import { Panel } from './Panel';
import { MenuMobileProps } from './types';
import classes from './MenuMobile.module.scss';

const ThemeSwitcher = dynamic(() => import('../../ThemeSwitcher/ThemeSwitcher'), { ssr: false });

export const MenuMobile = ({ theme, onClickLogo }: MenuMobileProps) => {
  const [[isMounted, isUnmounting], setIsMounted] = useDelayedUnmount(false, 300);
  const isMobile = useAppSelector(isMobileSelector);

  useEffect(() => {
    if (isMobile && isMounted) {
      setIsMounted(false);
    }
  }, [isMobile, isMounted, setIsMounted]);

  return (
    <>
      <Box justifyContent="space-between" className={cn(classes.contentMobile, classes[theme])} alignItems="center">
        <Logo onClick={onClickLogo} theme={theme} />
        <Box>
          <DocInfoIcon theme={theme} />
          <Box alignItems="center">
            <ThemeSwitcher />
          </Box>
        </Box>
      </Box>
      {isMounted && (
        <Panel {...{
          theme, isUnmounting, setIsMounted,
        }}
        />
      )}
    </>
  );
};
