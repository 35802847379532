import { Box } from 'uikit/Box';
import { Cross } from 'uikit/Cross';
import { Logo } from 'components/Header/Logo';
import { PanelHeaderProps } from './types';
import classes from './PanelHeader.module.scss';

export const PanelHeader = ({ theme, onClickCross }: PanelHeaderProps) => {
  return (
    <Box className={classes.container} justifyContent="space-between" alignItems="center">
      <Logo {...{ theme, className: classes.logo }} />
      <Cross theme={theme} onClick={onClickCross} />
    </Box>
  );
};
