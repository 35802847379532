import { memo, FC } from 'react';
import { Box } from 'uikit/Box';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { PageCounterProps } from './types';
import classes from './PageCounter.module.scss';

export const PageCounter: FC<PageCounterProps> = memo(({ showCount, totalCount, label }) => {
  const theme = useAppSelector(themeSelector);
  return (
    <Box className={classes[theme]}>
      <span className={classes.showing}>Showing:</span>
      <span data-testid="span-counter">{showCount || 0} of {totalCount || 0} {label}</span>
    </Box>
  );
});