import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { v1 as uuid } from 'uuid';
import isEqual from 'lodash.isequal';
import { usePrevious } from 'react-use';
import getConfig from 'config';
import { getAuthorizationHeader, isSSR, getAuthorizationToken } from 'common/utils';

export interface UseWebSocketProps<Data, Variables> {
  variables: Variables;
  operationName: string;
  query: any;
  onMessageData?: (data: Data, close: () => void) => void;
  onReconnect?: () => void;
  reconnectInterval?: number;
  attempts?: number;
  closed?: boolean;
}

export interface UseWebSocketResult<Data> {
  close: () => void;
  data?: Data;
}

const config = getConfig();
const url = config.NEXT_PUBLIC_API_ENDPOINT_WSS;

const maxReconnectInterval = 60000;
const stepReconnectInterval = 10000;

export function useWebSocket<Data, Variables = any>({
  variables,
  operationName,
  query,
  onMessageData,
  onReconnect = () => {},
  reconnectInterval: reconnectIntervalProps = stepReconnectInterval,
}: UseWebSocketProps<Data, Variables>): UseWebSocketResult<Data> {
  const [data, setData] = useState<Data>();
  const wsRef = useRef<WebSocket | null>(null);
  const idRef = useRef<string | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const reconnectInterval = useRef<number>(reconnectIntervalProps);
  const callReconnect = useRef<boolean>(false);

  const close = useCallback(() => {
    if (idRef.current && wsRef.current?.readyState === 1) {
      wsRef.current.send(JSON.stringify({ id: idRef.current, type: 'stop' }));
      wsRef.current.close();
      idRef.current = null;
      wsRef.current = null;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }, []);

  const open = useCallback(() => {
    wsRef.current = new WebSocket(url, 'graphql-ws');
    idRef.current = uuid();
    wsRef.current.onopen = () => {
      if (wsRef.current?.readyState === 1) {
        wsRef.current?.send(JSON.stringify({
          type: 'connection_init',
          payload: {
            authorization: getAuthorizationHeader(getAuthorizationToken()),
            'x-sdk-version': getConfig().NEXT_PUBLIC_SDK_VERSION,
          },
        }));
        wsRef.current?.send(
          JSON.stringify({
            id: idRef.current,
            type: 'start',
            payload: {
              variables,
              extensions: {},
              operationName,
              query,
            },
          }),
        );
        reconnectInterval.current = reconnectIntervalProps;
      }
    };

    wsRef.current.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      if (msg.type === 'data') {
        setData(msg.payload);
        onMessageData?.(msg.payload as Data, close);
      }
    };

    wsRef.current.onclose = (event) => {
      if (!event.wasClean && !callReconnect.current) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          onReconnect();
          open();
          callReconnect.current = false;
        }, reconnectInterval.current);
        reconnectInterval.current = reconnectInterval.current <= maxReconnectInterval
          ? reconnectInterval.current + stepReconnectInterval
          : reconnectInterval.current;
        callReconnect.current = true;
      }
    };
  }, [variables, operationName, query, reconnectIntervalProps, onMessageData, onReconnect, close]);

  const prevVariables = usePrevious(variables);

  useEffect(() => {
    if (!variables || isSSR()) return;
    if (!isEqual(prevVariables, variables)) {
      open();
    }
  }, [variables, prevVariables, open]);

  useEffect(() => {
    return () => {
      close();
    };
  }, [close]);

  return {
    data,
    close,
  };
}
