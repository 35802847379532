import Link from 'next/link';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { DocInfoIconProps } from './types';
import { href } from './helpers';
import classes from './DocInfoIcon.module.scss';

export const DocInfoIcon = ({ theme }: DocInfoIconProps) => (
  <Link
    href={href}
    target="_blank"
    className={classes.link}
  >
    <Box className={cn(classes.content, classes[theme])} alignItems="center" justifyContent="center">
      ?
    </Box>
  </Link>
);
