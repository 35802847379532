import { useCallback, useMemo } from 'react';
import Image from 'next/image';
import cn from 'classnames';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { windowSizeSelector } from 'lib/features/windowSize';
import { sliceWithDot } from 'common/utils';
import { themeSelector } from 'lib/features/theme';
import { Box } from 'uikit/Box';
import { Icon } from 'uikit/Icon';
import { useAuth } from 'common/contexts/Auth/AuthContext';
import { reset, selectedAddressSelector } from 'lib/features/wallet';
import classes from './AccountInfo.module.scss';

export const AccountInfo = () => {
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { width } = useAppSelector(windowSizeSelector);
  const theme = useAppSelector(themeSelector);
  const lengthPartWord = useMemo(() => (width > 380 ? 14 : 12), [width]);
  const onClick = useCallback(() => {
    logout();
    dispatch(reset());
  }, [logout, dispatch]);

  return (
    <Box className={cn(classes.content, classes[theme])} direction="column">
      <Box alignItems="center">
        <Image src="/icons/metamask.png" alt="metamask" width={23} height={23} className={classes.img} />
        {sliceWithDot(selectedAddress, lengthPartWord)}
        <Icon name="triangle" width={8} className={classes.icon} />
      </Box>
      <Box className={classes.btn} onClick={onClick}>Disconnect</Box>
    </Box>
  );
};
