import { Dropdown as DropdownCore } from 'react-bootstrap';
import {
  FC, memo, useCallback, useMemo,
} from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { Box } from 'uikit/Box';
import { Theme } from 'uikit/common/types';
import { DropdownToggle } from './DropdownToggle';
import { DropdownMenu } from './DropdownMenu';
import { DropdownProps } from './types';
import classes from './Dropdown.module.scss';

export const Dropdown: FC<DropdownProps> = memo(({
  items = [],
  active,
  classNameDropdownToggle,
  classNameDropdownMenu,
  className,
  renderToggleContent,
  isShowActive = true,
  onSelect,
  theme = Theme.dark,
  dataTestId = 'dropdown',
  showArrow,
  id = 'dropdown-basic',
}: DropdownProps) => {
  const activeItemInItems = useMemo(() => items.find(({ value }) => value === active), [items, active]);
  const filteredList = useMemo(
    () => (isShowActive ? items : items.filter(({ value }) => value !== active)),
    [items, isShowActive, active],
  );
  const onSelectValue = useCallback((eventKey: string | null) => {
    if (eventKey) {
      onSelect?.(filteredList[+eventKey - 1]?.value);
    }
  }, [onSelect, filteredList]);
  return (
    <DropdownCore onSelect={onSelectValue} className={cn(className, classes[theme])} data-testid={dataTestId}>
      <DropdownCore.Toggle
        id={id}
        className={cn(classes.dropdown, classNameDropdownToggle)}
        as={DropdownToggle}
        showArrow={showArrow}
      >
        {typeof renderToggleContent === 'function' ? renderToggleContent(activeItemInItems) : activeItemInItems?.label}
      </DropdownCore.Toggle>
      <DropdownCore.Menu
        className={cn(classes.dropdownMenu, classNameDropdownMenu)}
        as={DropdownMenu}
      >
        {filteredList.map(({ label, href, external }, idx) => (
          <DropdownCore.Item
            eventKey={(idx + 1).toString()}
            key={`${label}-${idx}`}
            className={classes.menuItem}
            as="div"
            data-testid={`${dataTestId}-item-${idx}`}
          >
            <Box alignItems="center">
              {href ? (
                <Link
                  href={href}
                  className={classes.menuitemtext}
                  target={external ? '_blank' : undefined}
                >
                  {label}
                </Link>
              ) : <span className={classes.menuitemtext}>{label}</span>}
            </Box>
          </DropdownCore.Item>
        ))}
      </DropdownCore.Menu>
    </DropdownCore>
  );
});

export default Dropdown;
