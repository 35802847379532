import {
  memo, FC, useCallback, useState,
} from 'react';
import { Modal } from 'uikit/Modal';
import { Button } from 'uikit/Buttons/Button';
import { themeSelector } from 'lib/features/theme';
import {
  loadingWalletSelector, isConnectedSelector, errorWalletSelector,
} from 'lib/features/wallet';
import { WalletType } from 'common/types/wallet';
import useWalletConnector from 'common/hooks/useWalletConnector';
import { useAppSelector } from 'lib/hooks';
import { ConnectWalletProps } from './types';
import { SelectWallet } from './SelectWallet';
import classes from './ConnectWallet.module.scss';

export const ConnectWallet: FC<ConnectWalletProps> = memo(() => {
  const { connectWallet } = useWalletConnector();
  const loadingWallet = useAppSelector(loadingWalletSelector);
  const theme = useAppSelector(themeSelector);
  const errorWallet = useAppSelector(errorWalletSelector);
  const isConnected = useAppSelector(isConnectedSelector);
  const [show, setShow] = useState(false);
  const onClose = useCallback(() => setShow(false), []);
  const onShow = useCallback(() => setShow(true), []);
  const onSuccess = useCallback(() => {
    onClose();
  }, [onClose]);
  const onConnect = useCallback((walletType: WalletType) => {
    connectWallet({
      walletType, isSendMetrics: true, onSuccess,
    });
  }, [connectWallet, onSuccess]);
  return (
    <>
      {!isConnected && (
        <Button
          theme={theme}
          variant="base"
          onClick={onShow}
          loading={loadingWallet}
          data-testid="button-connect-wallet"
        >
          Connect Wallet
        </Button>
      )}
      <Modal
        show={show}
        onClose={onClose}
        theme={theme}
        dialogClassName={classes.dialog}
        bodyClassName={classes.body}
      >
        <SelectWallet onConnect={onConnect} loading={loadingWallet} error={errorWallet} />
      </Modal>
    </>
  );
});