import { memo } from 'react';
import dynamic from 'next/dynamic';

import { ConnectWallet } from 'components/ConnectWallet';
import { useAppSelector } from 'lib/hooks';
import { isConnectedSelector } from 'lib/features/wallet';
import { themeSelector } from 'lib/features/theme';
import { Box } from 'uikit/Box';
import { AccountDropdown } from 'components/Account/AccountDropdown';
import { AccountBalance } from 'components/Account/AccountBalance';
import { DocInfoIcon } from './DocInfoIcon';
import { CheckConfidentiality } from './CheckConfidentiality';
import classes from './Account.module.scss';

const ThemeSwitcher = dynamic(() => import('../ThemeSwitcher/ThemeSwitcher'), { ssr: false });

export const Account = memo(() => {
  const isConnected = useAppSelector(isConnectedSelector);
  const theme = useAppSelector(themeSelector);

  return (
    <Box alignItems="center">
      {isConnected && <AccountBalance className={classes.mr} />}
      <DocInfoIcon theme={theme} />
      <ThemeSwitcher className={classes.mr} />
      {isConnected && (
        <>
          <AccountDropdown theme={theme} />
          <CheckConfidentiality theme={theme} />
        </>
      )}
      <ConnectWallet />
    </Box>
  );
});
