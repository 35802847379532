import {
  FC, memo,
} from 'react';
import ModalBootstrap from 'react-bootstrap/Modal';
import cn from 'classnames';

import { SquareButton } from 'uikit/Buttons/SquareButton';
import { Icon } from 'uikit/Icon';
import { Theme } from 'uikit/common/types';
import classes from './Modal.module.scss';
import { ModalProps } from './types';

export const Modal: FC<ModalProps> = memo(({
  dialogClassName,
  contentClassName,
  backdropClassName,
  bodyClassName,
  btnCloseClassName,
  children,
  onClose,
  showShadow = false,
  theme = Theme.dark,
  dataTestId = 'modal-window',
  ...props
}) => {
  return (
    <ModalBootstrap
      size="sm"
      centered
      dialogClassName={cn(classes.dialog, dialogClassName, classes[`root_${theme}`])}
      backdropClassName={cn(classes.backdrop, backdropClassName, classes[`backdrop_${theme}`])}
      contentClassName={cn(classes.content, contentClassName, { [classes.shadow]: showShadow }, classes[`content_${theme}`])}
      data-testid={dataTestId}
      {...props}
    >
      <ModalBootstrap.Body className={bodyClassName} data-testid={`${dataTestId}-body`}>
        {!!onClose && (
          <SquareButton
            variant="white"
            rounded
            className={cn(classes.btnClose, btnCloseClassName)}
            onClick={onClose}
            data-testid={`${dataTestId}-close`}
          >
            <Icon name="close" width={14} />
          </SquareButton>
        )}
        {children}
      </ModalBootstrap.Body>
    </ModalBootstrap>
  );
});

export default Modal;
