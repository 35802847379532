import { memo, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Account } from 'components/Account';
import { useToast } from 'uikit/Toast/hooks/useToast';
import { removeNotification, notificationsSelector } from 'lib/features/notifications';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Box } from 'uikit/Box';
import { MenuMobile } from './MenuMobile';
import { PageHead } from './PageHead';
import classes from './Header.module.scss';
import { GetTee } from './GetTee';
import { GetMatic } from './GetMatic';
import { Logo } from './Logo';

export const Header = memo(() => {
  const theme = useAppSelector(themeSelector);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(notificationsSelector);
  const { success, error } = useToast({ theme });
  const router = useRouter();

  useEffect(() => {
    const { notification, type } = notifications || {};
    if (notification) {
      if (type === 'success') {
        success(notification);
      } else {
        error(notification);
      }
      dispatch(removeNotification());
    }
  }, [error, notifications, success, dispatch]);

  const onClick = useCallback((e) => {
    e.preventDefault();
    router.push('/', undefined, { shallow: true });
  }, [router]);

  return (
    <Box className={cn(classes.wrap, classes[theme])} Tag="header" data-testid="header">
      <PageHead />
      <MenuMobile theme={theme} onClickLogo={onClick} />
      <Box alignItems="center" justifyContent="space-between" className={classes.content}>
        <Logo {...{ theme, onClick }} />
        <Box alignItems="center">
          <GetMatic className={classes.matic} />
          <GetTee className={classes.tee} />
          <Account />
        </Box>
      </Box>
    </Box>
  );
});

export default Header;